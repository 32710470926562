





































































import { Component, Vue, Watch } from "vue-property-decorator";
import Navbar from '@/components/common/Navbar.vue'; 
import DataTable from '@/components/common/Tables/DataTable.vue';
import { ILink, ITableHeader } from '@/interfaces/common';
import SideDrawer from '@/components/common/SideDrawer.vue';
import {IRequests} from '@/interfaces/request';
import SearchBar from "@/components/common/SearchBar.vue";
import Illustration from '@/components/common/Illustration.vue';
import RequestService from '@/services/RequestService';
import Snackbar from '@/components/common/Snackbar.vue'

@Component({
    components: {
        Navbar,
        DataTable,
        SideDrawer,
        Snackbar,
        SearchBar,
        Illustration
    }
})

export default class Create extends Vue {
private description: string = "No Requests Found" 
private subdescription: string = ""
private showOutletList: boolean = true
private OutletsLoading: boolean = false
private totalOutlets: number = 0
private message: string = ''
private snackbar: boolean = false
private timeout !: NodeJS.Timeout
private snackbarColor: string = 'success'
private menuItems: Array<ILink> = []
private requests: Array<IRequests> = []
private allRequests: Array<IRequests> = []
private pages: number = 0
private page: number = 1
private requestSearch: string = ""
private actionsTakenOnRequests: boolean = false
private sideDrawer: boolean = false
private tableHeaders: Array<ITableHeader> = [
    {
      text: "Request Type",
      value: "requestType",
      sortable: true,
      default: ''
    },
    {
      text: "Requester Name",
      value: "requestorName",
      sortable: false,
      default: ''
    },
    {
      text: "Request Date",
      value: "createdAt",
      sortable: false,
      default: 'NA'
    },
    {
      text: "Reporting Manager",
      value: "managerName",
      sortable: false,
      default: ''
    },
    {
      text: "Actions",
      value: "actions",
      sortable: false,
      default: ''
    }
  ] 
 private confirmationPopup: boolean = false
 private confirmationPopupTitle: string = ""
 private confirmationPopupSubtitle: string = ""
 private confirmationPopupText: string = ""
 private cancelBtnText: string = ""
 private confirmBtnText: string = ""
   
 

get formValidate(): Vue &{validate: () => boolean}{
            return this.$refs.form as Vue & {validate: () => boolean}
}
get formReset(): Vue &{reset: () => void}{
            return this.$refs.form as Vue & {reset: () => void}
}
get formResetValidation(): Vue &{resetValidation: () => void}{
            return this.$refs.form as Vue & {resetValidation:() => void}
}

@Watch('requestSearch')
        searchRequest(newVal: string){
            if(newVal && newVal.length > 2){
                this.timeout = setTimeout(() => {
                   this.actionsTakenOnRequests ? this.getActionTakenOnRequests(newVal,1) : this.getRequests(newVal,1);
                }, 1000);
            }else if(newVal.length == 0){
                this.timeout = setTimeout(() => {
                   this.actionsTakenOnRequests ? this.getActionTakenOnRequests("",1) : this.getRequests("",1);
                }, 1000);
            }
        }
public switchRequests(){
     if(this.actionsTakenOnRequests){
     this.tableHeaders = [
       {
      text: "Request Type",
      value: "requestType",
      sortable: true,
      default: ''
    },
    {
      text: "Requester Name",
      value: "requestorName",
      sortable: false,
      default: ''
    },
    {
      text: "Request Date",
      value: "createdAt",
      sortable: false,
      default: 'NA'
    },
    {
      text: "Reporting Manager",
      value: "managerName",
      sortable: false,
      default: ''
    },
    {
      text: "Action Taken",
      value: "actions",
      sortable: false,
      default: ''
    }
    ];
    this.getActionTakenOnRequests(this.requestSearch,1);
    }
    else{
    this.tableHeaders = [
       {
      text: "Request Type",
      value: "requestType",
      sortable: true,
      default: ''
    },
    {
      text: "Requester Name",
      value: "requestorName",
      sortable: false,
      default: ''
    },
    {
      text: "Request Date",
      value: "createdAt",
      sortable: false,
      default: 'NA'
    },
    {
      text: "Reporting Manager",
      value: "managerName",
      sortable: false,
      default: ''
    },
    {
      text: "Actions",
      value: "actions",
      sortable: false,
      default: ''
    }
    ];
    this.getRequests(this.requestSearch,1);
    }
}
public async getRequests(searchTerm: string, page: number): Promise<boolean>{
            this.OutletsLoading = true;
            return new Promise<boolean>(res => {
                RequestService.GetOutletRequests(this.requestSearch, page).then((response) => {
                  debugger;
                  this.requests = response.data.requests;
                    this.pages = response.data.pages;
                    this.totalOutlets = response.data.total;
                    this.OutletsLoading = false;
                    if(this.requests.length > 0){
                        this.showOutletList = true;
                    }else{
                        this.showOutletList = false;
                        this.description = this.requestSearch.length == 0 ? "No Request Found" : "No matching result found" ;
                        this.subdescription = this.requestSearch.length == 0 ? "" : "Make sure you typed correctly";
                    }
                    res(true);
                }).catch((error) => {
                    this.showCommunicationError(error);
                    console.log(error);
                    this.OutletsLoading = false;
                })
            })
        }
        public viewDeadOutletItems(request: IRequests): void{
          this.$router.push({ name: 'ReviewOutletRequests', params: { id: request.id.toString() } })
          }
        public showCommunicationError(err : any) {    
        this.snackbarColor = "red";
        this.snackbar = true;
        this.message = window.navigator.onLine ? `Some Error occured` : `Cannot reach server at the moment`;
        }
            public approvedDisapproved(request: IRequests) {    
              return request.isApproved == true;
        }
        public async getActionTakenOnRequests(searchTerm: string, page: number): Promise<boolean>{
            this.OutletsLoading = true; 
            return new Promise<boolean>(res => {
                RequestService.GetActionTakenOnRequests(searchTerm, page).then((response) => {
                    this.requests = response.data.requests;
                    this.pages = response.data.pages;
                    this.totalOutlets = response.data.total;
                    this.OutletsLoading = false;
                    if(this.requests.length > 0){
                        this.showOutletList = true;
                    }else{
                        this.showOutletList = false;
                        this.description = this.requestSearch.length == 0 ? "No Request Found" : "No matching result found" ;
                        this.subdescription = this.requestSearch.length == 0 ? "Please create a Request to begin with" : "Make sure you typed correctly or try finding in archieved Requests";
                    }
                    res(true);
                }).catch((error) => {
                    this.showCommunicationError(error);
                    console.log(error);
                    this.OutletsLoading = false;
                })
            })
        }
async created(){
        this.OutletsLoading = true;    
        this.getRequests(this.requestSearch,1);
         }
public async refreshOutletList(){
      this.actionsTakenOnRequests ? this.getActionTakenOnRequests(this.requestSearch, 1) :  await this.getRequests(this.requestSearch,1);
        }
public actionablePopup(){
            this.snackbar = true;
            this.confirmationPopup = false;
            this.showOutletList = true;
        } 
public closeSnackbar(value: boolean): void {
    this.snackbar = value;
    }
        
public changePage(value: number): void {
       this.page = value;
      this.actionsTakenOnRequests ? this.getActionTakenOnRequests(this.requestSearch, value) : this.getRequests(this.requestSearch, value);
  }
}
