import client from '../apiService';

class RequestService{
    async GetOutletRequests(searchTerm: string, page: number){
        const url = `Request/GetOutletRequests?searchTerm=${searchTerm}&page=${page}`;
        var result =  await client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        debugger;
        return result;
    }
    ActionTakenOnRequest(requestId: number, approved: boolean){
        const url = `Request/ActionTakenOnRequest?requestId=${requestId}&approved=${approved}`;
        return client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
    async GetOutletRequestItems(requestItemId: number){
        const url = `Request/GetOutletRequestItems?requestItemId=${requestItemId}`;
        var result =  await client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        debugger;
        return result;
    }
    async GetActionTakenOnRequests(searchTerm: string, page: number){
        const url = `Request/GetArchivedRequests?searchTerm=${searchTerm}&page=${page}`;
        var result =  await client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        debugger;
        return result;
    }
}
    export default new RequestService();